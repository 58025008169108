import { Grid, ImageList, ImageListItem } from "@mui/material"
import { useEffect, useState } from "react"
import TextData from "./components/TextData"
import TopBar from "./components/TopBar"
import Widget from "./components/Widget"

function App() {
  const [myMetrics, updateMyMetrics] = useState([])

  useEffect(() => {
    fetch("./input.json")
      .then((resp) => {
        return resp.json()
      })
      .then((data) => {
        let myMap = new Map(Object.entries(data))
        console.log(data)
        updateMyMetrics(myMap)
        console.log([...myMap])
      })
  }, [])

  return (
    <div className="App">
      <TopBar />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        // spacing={0.2}
      >
        {/* <Grid item xs> */}
        {/* <TextData /> */}
        {/* </Grid> */}
        {[...myMetrics].map((metric) => {
          console.log(metric)
          return (
            <Grid item>
              <Widget
                key={metric[1].title}
                name={metric[1].title}
                value={metric[1].value}
                unit={metric[1].unit}
              />
            </Grid>
          )
        })}
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs>
          <ImageList cols={1}>
            <ImageListItem>
              <img src="bb.png?w=248&fit=crop&auto=format" />
            </ImageListItem>
          </ImageList>
        </Grid>
        <Grid item>
          <ImageList cols={1}>
            <ImageListItem>
              <img
                src="solarpowerwiring.png"
                sizes="(max-width: 500px) 100vw, (max-width: 900px) 50vw, 800px"
              />
            </ImageListItem>
          </ImageList>
        </Grid>
      </Grid>
    </div>
  )
}

export default App
