import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import { ThemeProvider } from "@mui/material/styles"
import { createTheme } from "@mui/material/styles"
import toMaterialStyle from "material-color-hash"

import { format } from "d3-format"

const engNotation = format(".4s")

const fontOverride = createTheme({
  typography: {
    fontFamily: ["Teko"].join(","),
  },
})

function Widget(props) {
  const name = props.name
  const value = props.value
  const unit = props.unit

  return (
    <Box
      m={0.5}
      p={1}
      sx={{
        width: 150,
        height: 110,
        borderRadius: 2,
        display: "flex",
      }}
      style={toMaterialStyle(name, 700)}
    >
      <ThemeProvider theme={fontOverride}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
          wrap="no-wrap"
        >
          <Grid item xs={12}>
            <Typography
              sx={{ fontWeight: "medium" }}
              variant="h3"
              align="right"
              pb={0}
              mb={0}
            >
              {engNotation(value) + unit}
            </Typography>
          </Grid>
          <Grid item xs>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">{name.toUpperCase()}</Typography>
          </Grid>
        </Grid>{" "}
      </ThemeProvider>
    </Box>
  )
}

export default Widget
