import { Box, Paper, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"

function TextData() {
  const [data, updateData] = useState("")

  useEffect(() => {
    fetch("./v.txt")
      .then((resp) => {
        return resp.text()
      })
      .then((data) => updateData(data))
  })

  return (
    <Box m={2} sx={{ flexGrow: 1 }}>
      <Paper>
        <Typography p={3} style={{ whiteSpace: "pre-line" }}>
          {data}
        </Typography>
      </Paper>
    </Box>
  )
}

export default TextData
